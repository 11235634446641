<template>
  <div class="mobile-list-card">
    <div class="mobile-list-row">
      <div class="title">{{ code }}</div>
      <div :class="['bold', stateColor]">
        {{ stateName }}
      </div>
    </div>
    <div class="mobile-list-row">
      {{ name }}
    </div>
    <template v-for="(ext, index) in exts">
      <div class="mobile-list-row" :key="index" v-if="ext">
        {{ ext }}
      </div>
    </template>
    <div class="mobile-list-row" v-if="user || time">
      <div class="memo left">
        {{ user }}
      </div>
      <div class="memo right">
        {{ formatDate(time, "yyyy-MM-dd hh:mm") }}
      </div>
    </div>
    <div class="mobile-list-row" v-if="total || memo">
      <div class="total" v-if="total">￥{{ formatMoney(total) }}</div>
      <div class="memo" v-if="memo">
        {{ memo }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatMoney } from "@/utils/format.js";
export default {
  name: "order",
  props: {
    code: null,
    stateName: null,
    stateColor: null,
    name: null,
    user: null,
    time: null,
    total: null,
    memo: null,
    exts: null,
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
  },
  data: () => {
    return {};
  },
};
</script>